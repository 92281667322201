/*
    Author - Akshay Vishwakarma
    Description - This componenet is for option-2 form 
    to create new record
    - When user checks "by order name", Then this form gets
    displayed
    Jira (if any) - BCD - 4091
*/

import React, { useEffect, useState } from "react";
import {
    Col,
    Label,
    Row,
} from "reactstrap";
import { api } from "../../services/api";
import Select from 'react-select';
import ParentChildProductComponent from "./ParentChildProductComponent";


const ByOrderName = ({ url, orderId, setOrderId, force, setForce, selectedProduct, setSelectedProduct, selectedChildProduct, setSelectedChildProduct, }) => {

    const [orderList, setOrderList] = useState([]);
    const [paramsFilter, setParamsFilter] = useState({});

    useEffect(() => {
        setOrderList([{ value: "loading", label: "Loading...", isDisabled: true }])
        getAllOrderList()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsFilter])

    const getAllOrderList = () => {

        api
            .get(`flaviar/order/advent-batch/order-name`, {
                params: paramsFilter,
            })
            .then((response) => response)
            .then((response) => {
                if (response.status === 200) {
                    let currentStatuses = response.data.results.map((item) => ({
                        value: item.id,
                        label: item.name
                    }));
                    setOrderList(currentStatuses);
                }
            })
            .catch((err) => { });
    };

    let timer = null;

    const handleSearch = (e) => {
        // limpamos o timer
        clearTimeout(timer);
        // armazenamos o timer novamente
        timer = setTimeout(function () {
            setParamsFilter({
                ...paramsFilter,
                search: e.target.value,
            });
        }, 750);
    };

    return (
        <div>
            <Row className="mt-4" xs={12}>
                <Col xs={12}>
                    <Label className="mt-3">Search Order *</Label>
                    <br />
                    <Select
                        onChange={setOrderId}
                        options={orderList}
                        onKeyDown={handleSearch}
                        placeholder="Search Order"
                        value={orderId}
                    />
                </Col>
            </Row>
            <Row className="mt-1" xs={12}>
                <Col xs={12}>
                    <input
                        type="checkbox"
                        name="force"
                        checked={force}
                        onChange={() => setForce(!force)}
                    />
                    <Label className="ml-2">Force</Label>

                </Col>
            </Row>
            <Row>
                <ParentChildProductComponent
                    url={url}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    selectedChildProduct={selectedChildProduct}
                    setSelectedChildProduct={setSelectedChildProduct}
                />
            </Row>

        </div>
    )
}

export default ByOrderName